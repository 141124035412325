<template>
  <div class="page__wrapper">
    <ag-grid
      ref="entryConfirmGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <simple-upload
      ref="uploader"
      url=""
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    />
  </div>
</template>

<script>
import Vue from 'vue'

// const BASEURL = {
//   list: '/system/user/list'
// }

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleSaveClick', 'handleDeleteClick'],
  template: `<div style="height:100%;display:flex;justify-content:space-between;align-items:center;">
      <el-tooltip effect="light" :content="$t('dealer_entryConfirm.entryConfirm')">
        <el-button type="text" icon="el-icon-circle-check" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" content="取消">
        <el-button type="text" icon="el-icon-refresh-left" @click="handleDataSearch"/>
      </el-tooltip>
      <el-tooltip effect="light" content="删除">
        <el-button type="text" icon="el-icon-delete" @click="handleDeleteClick" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'DealerEntryConfirm',
  data () {
    return {
      searchModel: {},
      tableData: [{ dr: '1' }, { dr: '1' }, { dr: '1' }],
      gridOptions: {},
      isShowUploader: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          label: this.$t('cost_wholesale.month'),
          component: { clearable: true, type: 'month', valueFormat: 'yyyy-MM' }
        },
        { slotName: 'tableOperation', col: { xs: 18, sm: 18, md: 18 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_entryConfirm.dr'),
          field: 'dr',
          editable: true,
          cellEditorParams: {
            rules: { required: true }
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_entryConfirm.accountName'),
          field: 'accountName',
          editable: true,
          cellEditorParams: {
            rules: { required: true }
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_entryConfirm.money'),
          field: 'money',
          editable: true,
          type: 'NumberInput',
          cellEditorParams: {
            rules: { required: true, type: 'number' }
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_entryConfirm.updateBy'),
          field: 'money',
          editable: true,
          minWidth: 200
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 80,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    // this.handleDataSearch()
  },
  methods: {
    handleAddClick () {
      this.tableData.splice(0, 0, {})
    },
    handleDeleteClick () {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.$message({ type: 'success', message: '删除成功（模拟）' })
            done()
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleSaveClick (row) {
      this.$refs.entryConfirmGrid.validate(row, status => {
        if (status) {
          console.log('save data:', row.data)
          this.$message({ type: 'success', message: '保存成功（模拟）' })
          this.handleDataSearch()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
