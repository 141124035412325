<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('thirdParty_reportReview.anomalyReview') }}
    </div>
    <entry-confirm />
    <div slot="footer">
      <el-button
        @click="handleDialogClose"
      >
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <!-- <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button> -->
    </div>
  </el-dialog>
</template>
<script>
import entryConfirm from '../../dealer/entryConfirm/list'
const BASEURL = {
  update: '/system/user/update',
  get: '/system/user/get'
}
export default {
  name: 'AdjustEntries',
  components: { entryConfirm },
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: {}
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDialogOpen () {
      if (!this.detailData.id) {
        return
      }
      const loadingFlag = this.$loading({
        target: this.$el.querySelector('.el-dialog')
      })

      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = { company: {}, status: 'Y', roleList: [] }
    }
  }
}
</script>
